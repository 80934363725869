import { Component, OnInit } from '@angular/core';
import { RestaurantService } from '../restaurant.service';
import {Http} from '@angular/http';
import { HttpModule } from '@angular/http';
import { RestaurantDescription } from '../restaurant-description';
import { Headers, RequestOptions } from '@angular/http';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {

	constructor(
		private m_restaurant_service: RestaurantService,
		private http:Http,
		private modalService: NgbModal,
	) {}
  webcontentid = '';
  webcontent = '';
  webcontent1 = '';
  webcontent2 = '';
  webcontent3 = '';
  webcontent4 = '';
  webcontent5 = '';
  webcontent6 = '';
  webcontent7 = '';
  webcontent8 = '';
  gallery = [];
  mode = 'experience';



	ngOnInit(): void {
		this.showgallery('experience');
	}

	selectchange(type) : void {
    console.log("mode",type);
    this.mode = type.name;
      this.showgallery(this.mode );
    };

    showgallery(mode): void {
       this.mode = mode;
      if(mode === 'experience'){
        this.webcontentid = 'EXPERIENCE'
        this.webcontent = 'Perched on the second floor of the Intercontinental Singapore Robertson Quay, ISHI presents Japanese fine dining in an intimate and welcoming space with focus on seasonality and traditional techniques. ISHI, loosely translated as stone, is inspired by their texture and beauty, and signifies perseverance and strength. Our chefs merge their profound respect and knowledge of Japanese Cuisine along with the freshest catch from Japan to bring an extraordinary gastronomic and authentic dining experience to you.';
        this.webcontent1 = "";
        this.webcontent2 = '';
        this.webcontent3 = '';
        this.webcontent4 = '';
        this.webcontent5 = '';
        this.webcontent6 = '';
        this.webcontent7 = '';
        this.webcontent8 = '';

        this.gallery = [{
          id : '1',
          image : 'assets/images/ishilogo_min.jpg',
          name : 'experience',
          },
          ];
      }
      if(mode === 'space'){
        this.webcontentid = 'SPACE'
        this.webcontent = 'The restaurant boasts a main sushi counter, carved from Hinoki wood, with a full open kitchen concept that seats up to 12 diners and a 8 seater private counter, where you will be able to enjoy the chef at work in a more exclusive setting.';
        this.webcontent1 = 'Private Rooms are also available for corporate bookings or for those seeking a more intimate ambience.';
        this.webcontent2 = '';
        this.webcontent3 = '';
        this.webcontent4 = '';
        this.webcontent5 = '';
        this.webcontent6 = '';
        this.webcontent7 = '';
        this.webcontent8 = '';

        this.gallery = [{
          id : '1',
          image : 'assets/images/Mainsushicounter.jpg',
          name : 'experience',
          },
          {
            id : '2',
            image : 'assets/images/Privatesushicounter.jpg',
            name : 'experience1',
            },
            {
              id : '3',
              image : 'assets/images/Privateroom.jpg',
              name : 'experience3',
              },
          ];
      }
      if(mode === 'accolades'){
        this.webcontentid = 'ACCOLADES'
        this.webcontent = 'Wine & Dine Magazine Singapore’s Top Restaurants 2018/2019';
        this.webcontent1 = 'Peak Magazine G Restaurant Awards 2018';
        this.webcontent2 = '-Best New Restaurant (Asian)';
        this.webcontent3 = '- Award of Excellence';
        this.webcontent4 = 'The Straits Times Asian Masters Best Asian Restaurants (Bronze) 2019';
        this.webcontent5 = 'Singapore Tatler T Dining Best Restaurants 2018/2019';
        this.webcontent6 = 'Singapore Tatler T Dining Best Restaurants 2019/2020';
        this.webcontent7 = 'Robb Report Singapore Best of the Best 2020';
        this.webcontent8 = '- Dining (Japanese Omakase)';
        this.gallery = [{
          id : '1',
          image : 'assets/images/Sushi.jpg',
          name : 'accolades',
          },
          ];
      }
    };



}
