
import { Component, EventEmitter, Input, Output,Inject,ViewEncapsulation } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
  encapsulation: ViewEncapsulation.None
  
})

export class NavigationComponent {
  state: string = 'inactive';
  navbarOpen:boolean = false;
  navshow = false;

  ngOnInit(): void {
    this.navbarOpen = false;

  }

  constructor() {
  }

  toogleCollapse() {
    this.state = (this.state === 'inactive' ? 'active' : 'inactive')
  }

  clickedBook() {
    console.log("NavigationComponent clickedBook")

    //this.bookClicked.emit(true)

    //this.messageService.sendMessage('Message from NavigationComponent to Book Component!');
  }

  clickedReviews() {
    console.log("NavigationComponent clickedReviews")

   
  }

  clickedContactUs() {
    console.log("NavigationComponent clickedContactUs")
  }

  togglenav(){
    let background = document.getElementsByClassName("mainbackground");
    let mainnav = document.getElementsByClassName("mainnav");
    let footer = document.getElementsByClassName("footer");
    let menu = document.getElementById("menuid");
		if(this.navshow == false){
			this.navshow = true;
      (<HTMLElement>mainnav[0]).style.position = "absolute";
      (<HTMLElement>mainnav[0]).style.height = "100%";
      (<HTMLElement>mainnav[0]).style.zIndex = "20";
      (<HTMLElement>mainnav[0]).style.backgroundColor = "#d1ccbd";
      (<HTMLElement>mainnav[0]).style.transition = "all 500ms linear";
      (<HTMLElement>background[0]).style.display = "none";
      (<HTMLElement>footer[0]).style.position = "fixed";
      (<HTMLElement>footer[0]).style.display = "none";
      (document.getElementById('menuid') as HTMLImageElement).src = 'assets/images/close.png';
		}else {
      this.navshow = false;
      (<HTMLElement>mainnav[0]).style.position = "relative";
      (<HTMLElement>mainnav[0]).style.height = "auto";
      (<HTMLElement>mainnav[0]).style.background = "url('')";
      (<HTMLElement>mainnav[0]).style.transition = "all 500ms linear";
      (<HTMLElement>background[0]).style.display = "block";
      (<HTMLElement>footer[0]).style.position = "relative";
      (<HTMLElement>footer[0]).style.bottom = "0";
      (document.getElementById('menuid') as HTMLImageElement).src = 'assets/images/menu.png';
			
		}
	}
}
