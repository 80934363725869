import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit {
	
	constructor(
		private modalService: NgbModal,
	) {}

	navshow = false;
	

	ngOnInit(): void {
		//document.body.style.backgroundImage = "url('assets/images/mainbackground.jpg')";
	}

	


}
