import { Component, OnInit } from '@angular/core';
import { RestaurantDescription } from '../restaurant-description';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.css']
})
export class DescriptionComponent implements OnInit {
	
	constructor(

		private modalService: NgbModal,
	) {}
	m_descriptions: RestaurantDescription[];
	m_images;
	m_images2;
	name;
	webcontent;
	navshow = false;
	

	ngOnInit(): void {
		
	}

	


}
