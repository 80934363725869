import { Component, OnInit,ViewEncapsulation} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as $ from "jquery";

@Component({
	selector: 'home',
  	templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class HomeComponent {
 deviceInfo = null;
        device = "unknown";
	constructor(
		private Meta:Meta,
		private titleService: Title,
		private modalService: NgbModal,

  	) {}

  	ngOnInit(): void {
  	document.body.style.backgroundImage = "url('assets/images/mainbackground.jpg')";
  //   let element: HTMLElement = document.getElementById('openModal') as HTMLElement;
		//  element.click();
  //    $(window).on('load',function(){
  //     var delayMs = 10;
  //     setTimeout(function(){
  //      element.click();
  //     }, delayMs);
  // });
	}

  // open(content) {
  //   this.modalService.open(content);
  // }

	}

