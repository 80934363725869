import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';



import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { ReservationComponent } from './reservation/reservation.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { MenuComponent } from './menu/menu.component';
// import { GalleryComponent } from './gallery/gallery.component';


const APP_ROUTES: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent},
  {path: 'aboutus', component: AboutusComponent},
  {path: 'menu', component: MenuComponent},
  {path: 'contact', component: ContactComponent},
  // {path: 'gallery', component: GalleryComponent},
  {path: 'reservation', component: ReservationComponent},
  { path: '**', redirectTo: '/home', pathMatch: 'full'} ,


];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES)],
  exports: [RouterModule]

})

export class AppRoutingModule { }
