import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  gallery = [
    {
      id : '1',
      image : '/assets/images/Kamasu.jpg',
      name : 'Dining Partner',
      tags :'top',
      },
      {
      id : '2',
      image : '/assets/images/Hirame.jpg',
      name : 'dempsey',
      tags :'inner',
      },
       {
      id : '3',
      image : '/assets/images/AssortedSashimi.jpg',
      name : 'dempsey',
        tags :'inner',
      }, {
      id : '4',
      image : '/assets/images/BinchocharcoalaburiOtoro.jpg',
      name : 'Rochester',
        tags :'inner',
      }, {
      id : '5',
      image : '/assets/images/IkaUniNigiri.jpg',
      name : 'changi',
        tags :'inner',
      },{
      id : '6',
      image : '/assets/images/SpecialRiceBowl.jpg',
      name : 'changi',
        tags :'inner',
      },{
      id : '7',
      image : '/assets/images/Privatesushicounter.jpg',
      name : 'changi',
        tags :'inner',
      },{
      id : '8',
      image : '/assets/images/Privateroom.jpg',
      name : 'changi',
        tags :'inner',
      }



     ];
constructor() { }

ngOnInit() {

console.log("init galery",this.gallery);


}

}
